import {Box, Button, Dialog, DialogActions, Grid, TextField, Typography} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {useEffect, useRef, useState} from "react";
import {useAxios} from "../commons/axios/UseAxios";
import {bookingGet} from "../commons/apis/BookingGet";
import CommonUtils from "../utils/CommonUtils";
import InvoiceOverlay from "../overlay/InvoiceOverlay";

function BookingViewer() {

  const valueHallRef = useRef('')
  const [valueDateRef, setValueDateRef] = useState(null)
  const {
    execute: getBookingDetails,
    response: getBookingDetailsResponse,
    error: getBookingDetailsError,
  } = useAxios();

  const NoPaymentDone = "No Payment Record Found"

  const [dialogOpen, setDialogOpen] = useState(false);
  function  validate(){
    if(valueHallRef.current.value === undefined || valueHallRef.current.value === ''){
      alert("Select hall")
      return false;
    }
    if(valueDateRef === null){
      alert("Select Date")
      return false;
    }
    return true;
  }
  function submitClicked() {
    if (!validate()) {
      return;
    }
    const date = new Date(valueDateRef);
    date.setDate(date.getDate() + 1)
    const fetchParam = `${valueHallRef.current.value}/${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`;
    getBookingDetails(bookingGet(fetchParam)).then()
  }

  const handleClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    if(getBookingDetailsResponse === undefined)
      return;
    const statusCode = getBookingDetailsResponse?.status;
    if (statusCode !== 200) {
      alert("Failed to fetch booking detail")
      return
    }
    setDialogOpen(true)
  },[getBookingDetailsResponse]);

  return (
    <>
      {/* first line */}
        <Grid item xs={12} sm={12}>
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format={"DD-MM-YYYY"}
              value={valueDateRef}
              required
              onChange={(newValue) => setValueDateRef(newValue)}
              label="Booking Date"/>
          </LocalizationProvider>
          </Box>
          <br/>
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <TextField
              select
              SelectProps={{
                native: true
              }}
              label='Hall'
              name='hall'
              inputRef={valueHallRef}
              required
            >
              <option value=''/>
              <option value='VIRAT'>Virat Palace</option>
              <option value='JDP'>Jai Durga Palace</option>
            </TextField>
          </Box>
        </Grid>
      <Grid item xs={12} sm={12}>
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Button
            variant='outlined'
            onClick={() => {
              submitClicked();
            }}
            sx={{mt: 3, ml: 1}}>
            Fetch
          </Button>
        </Box>
      </Grid>
        {dialogOpen && (
            <InvoiceOverlay isOpen={dialogOpen} onClose={handleClose}  data={getBookingDetailsResponse.data}/>
        )}

    </>
  );
}
export default BookingViewer