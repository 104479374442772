export const EndPoint = {
  LOGIN: "/api/user/login",
  DASHBOARD: "/api/dashboard",
  ENQUIRY: "/api/enquiry",
  BOOKING: "/api/booking",
  LEDGER: "/api/ledger",
  LEDGERDATA: "/api/ledger-data",
  DAILYSHEET: "/api/daily-sheet",
  PAYMENT:"/api/booking-payment"
};
