import {Box, Button, Checkbox, Grid, InputAdornment, TextField} from "@mui/material";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {useEffect, useRef, useState} from "react";
import dayjs from 'dayjs';
import {useAxios} from "../commons/axios/UseAxios";
import {enquiry} from "../commons/apis/Enquiry";

export default function EnquiryPage() {

  const valueNameRef = useRef('')
  const [valueStartDateRef, setValueStartDateRef] = useState(null)
  const [valueEndDateRef, setValueEndDateRef] = useState(null)
  const valueHallRef = useRef('')
  const [valueEnquiryDateRef, setValueEnquiryDateRef] = useState(new dayjs())

  const valueCallerStateRef = useRef('')
  const valueCallerDistrictRef = useRef('')
  const valueCallerMobileNumberRef = useRef('')

  const [valueCallerSameAsCustomer, setValueCallerSameAsCustomer]  = useState('')
  const [valueCallerSameAsCustomerBool, setValueCallerSameAsCustomerBool]  = useState(true)

  const [valueCustomerStateRef, setValueCustomerStateRef] = useState('')
  const [valueCustomerDistrictRef, setValueCustomerDistrictRef] = useState('')
  const [valueCustomerMobileNumberRef, setValueCustomerMobileNumberRef] = useState('')

  const valueAmountQuoted = useRef('')
  const valueEnquirySourceRef = useRef('')
  const valueEnquiryTypeRef = useRef('')
  const valueEventTypeRef = useRef('')

  const valueCommentRef = useRef('')
  const valueEnquiryRespondedRef = useRef('')
  const {
    execute: executeEnquirySubmit,
    response: enquiryResponse,
    error: enquiryError,
  } = useAxios();

  const jharkhandDistricts = ['','Bokaro','Chatra','Deoghar','Dhanbad','Dumka','East Singhbhum','Garhwa','Giridih','Godda','Gumla','Hazaribagh','Jamtara','Khunti','Koderma','Latehar','Lohardaga','Pakur','Palamu','Ramgarh','Ranchi','Sahebganj','Seraikela Kharsawan','Simdega','West Singhbhum'];
  const biharDistricts = ['','Araria','Arwal','Aurangabad','Banka','Begusarai','Bhagalpur','Bhojpur','Buxar','Darbhanga','East Champaran','Gaya','Gopalganj','Jamui','Jehanabad','Kaimur','Katihar','Khagaria','Kishanganj','Lakhisarai','Madhepura','Madhubani','Munger','Muzaffarpur','Nalanda','Nawada','Patna','Purnia','Rohtas','Saharsa','Samastipur','Saran','Sheikhpura','Sheohar','Sitamarhi','Siwan','Supaul','Vaishali','West Champaran'];
  const [districtDropDownCustomer, setDistrictDropDownCustomer] = useState(null);
  const [districtDropDownCaller, setDistrictDropDownCaller] = useState(null);

  const customLocale = {
    timeZone: 'Asia/Kolkata',
  };



  function validate(data) {
    if (data.name === "" || data.name === undefined) {
      alert("Name can not be empty")
      return false;
    }
    // if(data.bookingFrom === "Invalid date" || data.bookingTo === "Invalid date" || data.bookingFrom > data.bookingTo){
    //   alert("Booking date not valid")
    //   return false;
    // }
    if(data.callerPhoneNumber === "" || data.callerDistrict === "" || data.callerState === ""){
      alert("Caller details missing")
      return false;
    }
    if(valueCallerSameAsCustomerBool && (data.customerPhoneNumber === "" || data.customerDistrict === "" || data.customerState === "")){
      alert("Customer details missing")
      return false;
    }
    if(data.source === ""){
      alert("Enquiry Source is missing")
      return false;
    }
    if(data.priceQuoted === "" ){
      alert("Price Quoted cannot be empty")
      return false;
    }
    if(data.eventType === "" ){
      alert("Event type cannot be empty")
      return false;
    }
    if(data.calledAccountId === "" ){
      alert("Please add who responded to enquiry")
      return false;
    }
    if(data.enquiryType === "" ){
      alert("Enquiry type cannot be empty")
      return false;
    }
    return true;
  }
  const submitClicked = () => {
    let fromDate = new Date(valueStartDateRef);
    fromDate.setDate(fromDate.getDate() +1)
    let toDate = new Date(valueEndDateRef);
    toDate.setDate(toDate.getDate() +1)

    if(valueStartDateRef === null || valueEndDateRef === null){
      fromDate = null;
      toDate = null;
    }


    const data = {
      name: valueNameRef.current.value,
      bookingFrom: fromDate,
      bookingTo: toDate,
      hall: valueHallRef.current.value,
      dateOfEnquiry: valueEnquiryDateRef,
      callerDistrict: valueCallerDistrictRef.current.value,
      callerState: valueCallerStateRef.current.value,
      callerPhoneNumber: valueCallerMobileNumberRef.current.value,
      customerDistrict: (valueCallerSameAsCustomerBool) ? valueCallerDistrictRef.current.value : valueCustomerDistrictRef.current.value,
      customerState: (valueCallerSameAsCustomerBool) ? valueCallerStateRef.current.value : valueCustomerStateRef.current.value,
      customerPhoneNumber: (valueCallerSameAsCustomerBool) ? valueCallerMobileNumberRef.current.value : valueCustomerMobileNumberRef.current.value,
      source : valueEnquirySourceRef.current.value,
      priceQuoted: valueAmountQuoted.current.value,
      comments: valueCommentRef.current.value,
      enquiryType: valueEnquiryTypeRef.current.value,
      eventType: valueEventTypeRef.current.value,
      calledAccountId: valueEnquiryRespondedRef.current.value
    };
    if(validate(data)){
      executeEnquirySubmit(enquiry(data)).then();
    }
  }

  useEffect(() => {
    const statusCode = enquiryResponse?.status;
    if (statusCode === 200) {
      alert("Enquiry Registered Successfully")
      window.location.reload();
    }
    else if(enquiryError !== undefined && enquiryError?.code === "ERR_BAD_REQUEST"){
      alert(enquiryError.response.data.errors)
    }
  }, [enquiryResponse,enquiryError]);



  return (
    <>
      {/* first line */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            label='Name'
            name='Name'
            placeholder='Customer name'
            inputRef={valueNameRef}
            required
          />
        </Grid>
        {/* second line */}
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format={"DD-MM-YYYY"}
              disablePast
              value={valueStartDateRef}
              onChange={(newValue) => setValueStartDateRef(newValue)}
              locale={customLocale}
              label="Check-in"/>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format={"DD-MM-YYYY"}
              minDate={valueStartDateRef}
              value={valueEndDateRef}
              onChange={(newValue) => setValueEndDateRef(newValue)}
              locale={customLocale}
              disablePast label="Check-out"/>
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            select
            SelectProps={{
              native: true
            }}
            label='Hall'
            name='hall'
            inputRef={valueHallRef}
            required
          >
            <option value=''/>
            <option value='VIRAT'>Virat Palace</option>
            <option value='JDP'>Jai Durga Palace</option>
            <option value='BOTH'>Virat/Jai Durga</option>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format={"DD-MM-YYYY"}
              value={valueEnquiryDateRef}
              onChange={(newValue) => setValueEnquiryDateRef(newValue)}
              disableFuture
              locale={customLocale}
              label="Enquiry Date"/>
          </LocalizationProvider>
        </Grid>

        {/* third line */}

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            select
            SelectProps={{
              native: true
            }}
            label='Caller State'
            name='callerState'
            inputRef={valueCallerStateRef}
            onChange={(value) =>{
              if(valueCallerSameAsCustomerBool){
                setValueCustomerStateRef(value)
              }
              if(valueCallerStateRef.current.value === "Jharkhand")
                setDistrictDropDownCaller(jharkhandDistricts);
              else if(valueCallerStateRef.current.value === "Bihar")
                setDistrictDropDownCaller(biharDistricts);
              else
                setDistrictDropDownCaller(['Others'])
            }}
          >
            <option value=''/>
            <option value="Jharkhand">Jharkhand</option>
            <option value="Bihar">Bihar</option>
            <option value="Others">Others</option>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            select
            SelectProps={{
              native: true
            }}
            label='Caller District'
            name='callerDistrict'
            inputRef={valueCallerDistrictRef}
            onChange={(value) =>{
              if(valueCallerSameAsCustomerBool){
                setValueCustomerDistrictRef(value)
              }
            }}
          >
            {districtDropDownCaller?.map(option => {
              return (
                  <option value={option}>
                    {option}
                  </option>
              );
            })}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label='Caller Mobile number'
            name='callerMobileNumber'
            placeholder='Caller Mobile Number'
            inputRef={valueCallerMobileNumberRef}
            type="number"
            onChange={(value) =>{
              if(valueCallerSameAsCustomerBool){
                setValueCustomerMobileNumberRef(value)
              }
            }}
          />
        </Grid>

        {/* fourth line */}
        <Grid item xs={12} sm={3}>
          <Checkbox
            defaultChecked
            value={valueCallerSameAsCustomer}
            onChange={(value) =>{
              (setValueCallerSameAsCustomer(value))
              setValueCallerSameAsCustomerBool(value?.target?.checked)
              if(!valueCallerSameAsCustomerBool){
                setValueCustomerMobileNumberRef(valueCallerMobileNumberRef)
                setValueCustomerStateRef(valueCallerStateRef)
                setValueCustomerDistrictRef(valueCallerDistrictRef)
              }
            }}
            required/> Same As Caller
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            select
            SelectProps={{
              native: true
            }}
            label='Customer State'
            name='customerState'
            inputRef={valueCustomerStateRef}
            disabled={valueCallerSameAsCustomerBool}
            onChange={(value) =>{
              if(valueCustomerStateRef.current.value === "Jharkhand")
                setDistrictDropDownCustomer(jharkhandDistricts);
              else if(valueCustomerStateRef.current.value === "Bihar")
                setDistrictDropDownCustomer(biharDistricts);
              else
                setDistrictDropDownCustomer(['Others'])
            }}
          >
            <option value=''/>
            <option value="Jharkhand">Jharkhand</option>
            <option value="Bihar">Bihar</option>
            <option value="Others">Others</option>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            select
            SelectProps={{
              native: true
            }}
            label='Customer District'
            name='customerDistrict'
            inputRef={valueCustomerDistrictRef}
            disabled={valueCallerSameAsCustomerBool}
          >
            {districtDropDownCustomer?.map(option => {
              return (
                  <option value={option}>
                    {option}
                  </option>
              );
            })}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label='Customer Mobile number'
            name='customerMobileNumber'
            placeholder='Customer Mobile Number'
            required
            type="number"
            disabled={valueCallerSameAsCustomerBool}
            inputRef={valueCustomerMobileNumberRef}
          />
        </Grid>

        {/* fifth line */}

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label='Amount Quoted'
            name='Amount Quoted'
            placeholder='Amount Quoted'
            type="number"
            inputRef={valueAmountQuoted}
            required

            InputProps={{
              startAdornment: <InputAdornment position="start">₹</InputAdornment>,
              inputMode: 'numeric', pattern: '[0-9]*'
            }}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            select
            SelectProps={{
              native: true
            }}
            label='Event Type'
            name='eventType'
            inputRef={valueEventTypeRef}
          >
            <option value=''/>
            <option value='MARRIAGE'>MARRIAGE</option>
            <option value='BIRTHDAY'>BIRTHDAY</option>
            <option value='UPANAYANA'>UPANAYANA</option>
            <option value='OTHERS'>OTHERS</option>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            select
            SelectProps={{
              native: true
            }}
            label='Enquiry Source'
            name='source'
            inputRef={valueEnquirySourceRef}
          >
            <option value=''/>
            <option value='FACEBOOK'>FACEBOOK</option>
            <option value='JD'>JD</option>
            <option value='FRIEND'>FRIEND</option>
            <option value='SELF'>SELF</option>
            <option value='GOOGLE'>GOOGLE</option>
            <option value='FAMILY'>FAMILY</option>
            <option value='OTHERS'>OTHERS</option>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            select
            SelectProps={{
              native: true
            }}
            label='Enquiry Type'
            name='enquiryType'
            inputRef={valueEnquiryTypeRef}
          >
            <option value=''/>
            <option value='PERSON'>PERSON</option>
            <option value='CALL'>CALL</option>
            <option value='OTHERS'>OTHERS</option>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={9}>
          <TextField
            fullWidth
            label='Comment'
            name='comment'
            placeholder='Comment'
            multiline
            rows={2}
            inputRef={valueCommentRef}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            select
            SelectProps={{
              native: true
            }}
            label='Responded by'
            name='respondedBy'
            inputRef={valueEnquiryRespondedRef}>
            <option value=''/>
            <option value='3f4cffcd-70c8-4246-8e4d-158325deb2d1'>Dilip</option>
            <option value='de2da5b2-77a9-4120-85e8-fa6493df8789'>Somnath</option>
            <option value='506d848f-1439-4eea-9ce7-0c4c0bf45ef2'>Vijay</option>
          </TextField>
        </Grid>

      </Grid>


      <Grid item xs={12} sm={6}>
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Button
            variant='outlined'
            onClick={() => {
              submitClicked();
            }}
            sx={{mt: 3, ml: 1}}>
            Submit Enquiry
          </Button>
        </Box>
      </Grid>
    </>
  );
}
