import React, {useEffect, useState} from "react";
import axios from "axios";
import Authorization from "../token/authorization";
import { constants } from "../../Constants";
import CommonUtils from "../../utils/CommonUtils";
import {CircularProgress} from "@mui/material";

export const useAxios = () => {
  const [response, setResponse] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const indicatorSize = 80;

  const token = Authorization.getAccessToken();

  useEffect(() => {
    if(loading){
      //show the loader
      toggleLoader(true)
    }
    else{
      //hide the loader
      toggleLoader(false)
    }
  }, [loading]);

  const toggleLoader = (value) => {
    return (
      <div style={{ position: 'relative'}}>
        {value && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 9999,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              pointerEvents: 'none',
            }}>
            <CircularProgress size={indicatorSize} color="primary" />
          </div>
        )}

      </div>
    );
  };

  const execute = async (params) => {
    if (params) {
      const {headers = {}, url = ""} = params || {};
      // axios.defaults.baseURL = "http://localhost:8080";
      axios.defaults.baseURL = "https://api.viratjdpalace.com";
      if (!url.includes("/login")) headers.Authorization = `Bearer ${token}`;
      else {
        delete headers.Authorization;
      }

      try {
        setLoading(true);
        const result = await axios.request(params);
        setResponse(result);
      } catch (err) {
        if (err?.response?.status === 500)
          alert("Failed to get response")
        else if (err?.response?.status === 400)
          alert(err.response.data.errors)
        else if(err?.code === 'ERR_NETWORK' || err?.code === 401){
          alert("Login expired, please re login")
          Authorization.clearAccessToken();
          window.location = '/login';
        }
        setError(err);
      } finally {
        setLoading(false);
      }
    }
  };
  return { execute, loading, response, error };
};
