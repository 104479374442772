import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField
} from '@mui/material';
import CommonUtils from "../utils/CommonUtils";
import {enquiryClose, updateEnquiry} from "../commons/apis/Enquiry";
import {useAxios} from "../commons/axios/UseAxios";

const EnquiryOverlay = ({ isOpen, onClose, data}) => {
    const [valueBookingConverted, setValueBookingConverted] = useState('')
    const valueCommentRef = useRef('')
    const valueEnquiryState = useRef('')

    const {
        execute: executeEnquiryClose,
        response: executeEnquiryCloseResponse,
        error: executeEnquiryCloseError,
    } = useAxios();

    const {
        execute: executeUpdateEnquiry,
        response: executeUpdateEnquiryResponse,
        error: executeUpdateEnquiryError,
    } = useAxios();

    useEffect(() => {
        const statusCode = executeEnquiryClose?.status;
        if (statusCode === 200) {
            alert("Enquiry Closed Successfully")
            window.location.reload();
        } else if (executeEnquiryCloseError !== undefined && executeEnquiryCloseError?.code === "ERR_BAD_REQUEST") {
            alert(executeEnquiryCloseError.response.data.errors)
        }
    }, [executeEnquiryCloseResponse, executeEnquiryCloseError]);


    const closeEnquiryClicked = () => {
        const apiData = {
            closingComment: valueCommentRef.current.value,
        };
        executeEnquiryClose(enquiryClose(apiData,data.id)).then();
    }

    const updateEnquiryClicked = () => {
        // Doing this because it takes date at GMT time hence it shows one day less
        const dropDownValue = valueEnquiryState?.current?.value
        if (dropDownValue === undefined || dropDownValue === 'CREATED') {
            alert("Update Dropdown")
            return false;
        }

        const comment = valueCommentRef?.current?.value
        const apiData = {
            status: dropDownValue,
            comment: valueCommentRef.current.value,
        };
        executeUpdateEnquiry(updateEnquiry(apiData,data.id)).then()
    }

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            <DialogTitle>Open Enquiry , ID : {data.id},Hall : {data.hall}</DialogTitle>
            <DialogContent>
                <p>Name : {data.name}, {data.callerPhoneNumber}, {data.callerDistrict}</p>
                <p>From : {CommonUtils.getDateFromDateArray(data.bookingFrom)}, To
                    : {CommonUtils.getDateFromDateArray(data.bookingTo)}</p>
                <br></br>
                <p>Current Status : {data.enquiryStatusEnum}</p>
                <br></br>
                <Grid item xs={12} sm={3}>
                    <TextField
                        fullWidth
                        select
                        SelectProps={{
                            native: true
                        }}
                        label='Enquiry State'
                        name='enquiryState'
                        inputRef={valueEnquiryState}
                        required
                    >
                        <option value='CREATED'>Created</option>
                        <option value='NOT_CONTACTED'>Not Contacted</option>
                        <option value='CONTACT_LATER'>Contact Later</option>
                        <option value='CONTACTED'>Contacted</option>
                        <option value='JUNK'>Junk</option>
                        <option value='LOST'>Lost</option>
                        <option value='BUDGET'>Budget</option>
                        <option value='ALREADY_BOOKED'>Already Booked</option>
                        <option value='OTHERS'>Others</option>
                    </TextField>
                </Grid>
                <br></br>
                <Grid item xs={12} sm={9}>
                    <TextField
                        fullWidth
                        label='Add Comment'
                        name='addComment'
                        placeholder='Add Comment'
                        multiline
                        rows={2}
                        inputRef={valueCommentRef}
                    />
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={closeEnquiryClicked} size = "small" variant="contained" color="success">
                    Close Enquiry
                </Button>
                <Button onClick={updateEnquiryClicked} variant="contained" color="primary">
                    Update Enquiry
                </Button>
                <Button onClick={onClose} variant="contained" color="error">
                    Close Window
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default EnquiryOverlay;