import {useEffect, useState} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid, InputAdornment,
  Table,
  TableCell,
  TableHead,
  TableRow, TextField, Typography
} from "@mui/material";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import TheTableRows from "./TheTableRows"
import {useAxios} from "../commons/axios/UseAxios";
import {ledgerGet} from "../commons/apis/Ledger";
import {dailySheetSave} from "../commons/apis/DailySheetSave";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const nonBookingledgers = [];
const bookingledgers = [];
function AddDailySheet() {

  const {
    execute: executeNonBookingLedgerGet,
    response: ledgerNonBookingResponse,
    error: ledgerNonBookingError,
  } = useAxios();

  const {
    execute: executeBookingLedgerGet,
    response: ledgerBookingResponse,
    error: ledgerBookingError,
  } = useAxios();

  const {
    execute: submitDailySheet,
    response: submitDailySheetResponse,
    error: submitDailySheetError,
  } = useAxios();

  const [valueDateRef, setValueDateRef] = useState(new dayjs())
  // const [valueBalanceBeforeRef, setBalanceBeforeRef] = useState()

  const [rowsData, setRowsData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const modelStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    overflow: 'scroll',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  const validateLastEntry = () => {
    if (nonBookingledgers.length === 0 && bookingledgers.length === 0) {
      alert("Ledger not downloaded please wait,\n If doesnt download in 1 minutes logout/login");
      return false;
    }
    let rowLength = rowsData.length;
    if (rowLength === undefined)
      rowLength = 0
    if (rowLength > 0) {
      if (rowsData[rowLength - 1].particulars === '') {
        alert("Please add particulars to last entry")
        return false;
      }
      if ((rowsData[rowLength - 1].debit === 0 && rowsData[rowLength - 1].credit === 0)) {
        alert("Please add credit/debit to last entry")
        return false;
      }
      if (rowsData[rowLength - 1].ledger.label === '' ||
        rowsData[rowLength - 1].ledger.id === 0) {
        alert("Please add ledger to last entry")
        return false;
      }
    }
    return true;
  }
  const addTableRows = () => {
    if (validateLastEntry() === false)
      return;

    const rowLength = rowsData.length;

    const rowsInput = {
      daySerialNumber: rowLength + 1,
      particulars: '',
      credit: 0,
      debit: 0,
      ledger: {
        label: '',
        id: 0,
      },
      date: "",
      autoCompleteList: nonBookingledgers,
      isBooking: false
    }
    setRowsData([...rowsData, rowsInput])
  }

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  }

  const handleCheckBoxChange = (index, evnt) => {
    const value = evnt.target.checked;
    const rowsInput = [...rowsData];
    rowsInput[index].isBooking = value;

    if(value === true){
      rowsInput[index].isBooking = true;
      rowsInput[index].ledger.label = "";
      rowsInput[index].ledger.id = undefined;
      rowsInput[index].autoCompleteList = bookingledgers;
    }else {
      rowsInput[index].isBooking = false;
      rowsInput[index].autoCompleteList = nonBookingledgers;
      rowsInput[index].ledger.label = "";
      rowsInput[index].ledger.id = undefined;
    }
    setRowsData(rowsInput);
  }
  const handleLedgerChange = (index, evnt) => {
    const value = evnt.currentTarget.innerText;
    if(value === '')
      return;
    const rowsInput = [...rowsData];
    const result = rowsInput[index].isBooking ? bookingledgers.filter(ledger => ledger.label === value) :
      nonBookingledgers.filter(ledger => ledger.label === value);

    if (result.length !== 1) {
      alert("Unable to set ledger talk to Bunty/Piyush")
      return
    }
    rowsInput[index].ledger.label = value;
    rowsInput[index].ledger.id = result.at(0).ledgerId;
    setRowsData(rowsInput);
  }
  const handleChange = (index, evnt) => {
    const {name, value} = evnt.target;
    const rowsInput = [...rowsData];
    if (name === 'credit' && (rowsInput[index].debit > 0)) {
      alert("Enter value in credit or debit")
      rowsInput[index].credit = 0
      return;
    }

    if (name === 'debit' && (rowsInput[index].credit > 0)) {
      alert("Enter value in credit or debit")
      rowsInput[index].debit = 0
      return;
    }
    rowsInput[index][name] = value;

    // amount not allowed over 10 lakhs
    if (name === 'debit' && value > 999999) {
      alert("Debit amount will out of limit, not allowed")
      rowsInput[index].debit = value.substring(0, 6)
    }

    if (name === 'credit' && value > 999999) {
      alert("Credit amount will out of limit, not allowed")
      rowsInput[index].credit = value.substring(0, 6)
    }
    setRowsData(rowsInput);
  }

  useEffect(() => {
    const statusCode = ledgerNonBookingResponse?.status;
    if (statusCode === 200) {
      const listItems = ledgerNonBookingResponse?.data;
      nonBookingledgers.length = 0;
      listItems.map((ledgerResponse) => {
        nonBookingledgers.push({
          label: `${ledgerResponse.name}`,
          ledgerId: `${ledgerResponse.id}`,
        })
      });
    }
  }, [ledgerNonBookingResponse]);

  useEffect(() => {
    const statusCode = ledgerBookingResponse?.status;
    if (statusCode === 200) {
      const listItems = ledgerBookingResponse?.data;
      bookingledgers.length = 0;
      listItems.map((ledgerResponse) => {
        bookingledgers.push({
          label: `${ledgerResponse.name}`,
          ledgerId: `${ledgerResponse.id}`,
        })
      });
    }
  }, [ledgerBookingResponse]);

  useEffect(() => {
    const requestParam = `get-active?isBooking=false`
    executeNonBookingLedgerGet(ledgerGet(requestParam)).then();
  }, []);

  useEffect(() => {
    const requestParam = `get-active?isBooking=true`
    executeBookingLedgerGet(ledgerGet(requestParam)).then();
  }, []);

  const handleSubmit = () => {
    const date = new Date(valueDateRef);
    date.setDate(date.getDate())
    const dateUpdatedRowsData = rowsData.map((row) => {
      return{...row, date: date }
    })

    if (validateLastEntry() === false)
      return;
    console.log(dateUpdatedRowsData)
    submitDailySheet(dailySheetSave(dateUpdatedRowsData)).then(res => {
      const statusCode = submitDailySheetResponse?.status;
      if (statusCode === 200) {
        alert("Data saved successfully")
      }
      setDialogOpen(true)
    })
  }

  const modalClose = () => {
    setDialogOpen(false);
    window.location.reload();
  }

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleChangeBF = (event) => {
    const inputVal = event.target.value;
    if (inputVal === '' || Number(inputVal) <= 249999) {
      setBalanceBeforeRef(inputVal);
    }
  };


  return (

    <div className="container">
      <div>
        <Grid container spacing={2} >
          <Grid item xs={12} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                format={"DD-MM-YYYY"}
                value={valueDateRef}
                onChange={(newValue) => setValueDateRef(newValue)}
                disableFuture
                label="Date"/>
          </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={2} ></Grid>
          <Grid item xs={12} sm={2} ></Grid>
          {/*<Grid item xs={12} sm={2} >*/}
          {/*  <LocalizationProvider dateAdapter={AdapterDayjs}>*/}
          {/*    <TextField*/}
          {/*        fullWidth*/}
          {/*        label='B/F'*/}
          {/*        name='B/F'*/}
          {/*        placeholder='B/F'*/}
          {/*        value={valueBalanceBeforeRef}*/}
          {/*        onChange={handleChangeBF}*/}
          {/*        required*/}
          {/*        disabled*/}
          {/*        type="number"*/}
          {/*        InputProps={{*/}
          {/*          startAdornment: <InputAdornment position="start">₹</InputAdornment>,*/}
          {/*          inputMode: 'numeric', pattern: '[0-9]*',*/}
          {/*          max: 250000,*/}
          {/*        }}/>*/}
          {/*  </LocalizationProvider>*/}
          {/*</Grid>*/}
        </Grid>
      </div>

      <br/>
      <div className="row">
        <div className="col-sm-8">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sr.No</TableCell>
                <TableCell>Particulars</TableCell>
                <TableCell>Credit</TableCell>
                <TableCell>Debit</TableCell>
                <TableCell>Ledger</TableCell>
                <TableCell>Is Booking</TableCell>
                <TableCell><Button onClick={addTableRows} variant='outlined' color={"info"}><AddOutlinedIcon/></Button></TableCell>
              </TableRow>

            </TableHead>
            <tbody>

            <TheTableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange}
                          handleLedgerChange={handleLedgerChange} handleCheckBoxChange={handleCheckBoxChange}/>

            </tbody>
          </Table>

          <Box textAlign='center'>
            <br/>
            <Button
              onClick={handleSubmit}
              variant='contained'>
              Submit
            </Button>
          </Box>

        </div>
        <div className="col-sm-4"/>
      </div>
      <Dialog
        fullScreen
        open={dialogOpen}
        scroll={'paper'}
        onClose={() => modalClose()}
      >
        <DialogContent>
          <Box sx={{...modelStyle, width: '80vw'}}>
            <Table>
              <TableHead>
                <TableCell>Sr.No</TableCell>
                <TableCell>Particulars</TableCell>
                <TableCell>Credit</TableCell>
                <TableCell>Debit</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Ledger</TableCell>
              </TableHead>
              <tbody>
              {submitDailySheetResponse?.data?.map(c => {
                return <TableRow>
                  {Object.keys(c).map(k => <TableCell>{`${c[k]}`}</TableCell>)}
                </TableRow>
              })}
              </tbody>
            </Table>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={modalClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}


export default AddDailySheet