import {Autocomplete, Button, Checkbox, TableCell, TableRow, TextField} from "@mui/material";
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';

function TheTableRows({rowsData, deleteTableRows, handleChange, handleLedgerChange, handleCheckBoxChange}) {
  return(

    rowsData.map((data, index)=>{
      const {particulars, credit, debit, ledger, autoCompleteList, isBooking }= data;
      return(
        <TableRow key={index}>
          <TableCell>{index+1}</TableCell>
          <TableCell width={"40%"}>
            <TextField size={"small"} type="text" fullWidth value={particulars} onChange={(evnt)=>(handleChange(index, evnt))} name="particulars"/>
          </TableCell>
          <TableCell width={"15%"}><TextField size={"small"} type="number" value={credit}  onChange={(evnt)=>(handleChange(index, evnt))} name="credit" /> </TableCell>
          <TableCell width={"15%"}><TextField size={"small"} type="number" value={debit}  onChange={(evnt)=>(handleChange(index, evnt))} name="debit" /> </TableCell>
          <TableCell width={"20%"}><Autocomplete size={"small"} value={ledger} options={autoCompleteList} disablePortal onChange={(evnt)=>(handleLedgerChange(index, evnt))}
                                                 name="ledger" renderInput={(params) => <TextField {...params} label="ledger" /> }/> </TableCell>
          <TableCell><Checkbox label="IsBooking" value={isBooking} variant="solid" onChange={(evnt)=>(handleCheckBoxChange(index, evnt))} /></TableCell>
          <TableCell><Button  sx={{mt: 3, ml: 1}}  variant='outlined' color={"error"} onClick={()=>(deleteTableRows(index))}><RemoveCircleOutlineOutlinedIcon/></Button></TableCell>
        </TableRow>
      )
    })
  )
}export default TheTableRows;