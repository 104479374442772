// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'calendar',
    path: '/dashboard/app',
    icon: icon('calendar'),
  },
  {
    title: 'enquiry',
    path: '/dashboard/enquiry',
    icon: icon('enquiry'),
  },
  {
    title: 'enquiry-viewer',
    path: '/dashboard/enquiry-viewer',
    icon: icon('enquiry-viewer'),
  },
  {
    title: 'booking',
    path: '/dashboard/booking',
    icon: icon('booking'),
  },
  {
    title: 'booking details',
    path: '/dashboard/booking-viewer',
    icon: icon('booking-details'),
  },
  {
    title: 'daily sheet',
    path: '/dashboard/daily-sheet',
    icon: icon('daily-sheet'),
  },
  {
    title: 'daily sheet viewer',
    path: '/dashboard/daily-sheet-viewer',
    icon: icon('daily-sheet-viewer'),
  },
  {
    title: 'ledger Data',
    path: '/dashboard/ledger',
    icon: icon('ledger'),
  },
  {
    title: 'stock management',
    path: '/dashboard/stock-management',
    icon: icon('stock'),
  },
  {
    title: 'logout',
    path: '/login',
    icon: icon('logout'),
  },
];

export default navConfig;
