import {EndPoint} from "./endPoint";
import {AUTH_SERVICE_HEADERS} from "./headers";

export const ledgerCreate = (_data) => {
  const url = `${EndPoint.LEDGER}/add`;
  return {
    method: "POST",
    url: `${url}`,
    headers: AUTH_SERVICE_HEADERS,
    data: _data,
  };
};
