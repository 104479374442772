import {EndPoint} from "./endPoint";
import {AUTH_SERVICE_HEADERS} from "./headers";

export const getLedgerByIdAPI = (_data) => {
  const url = `${EndPoint.LEDGERDATA}/get-ledger-data`;
  return {
    method: "POST",
    url: `${url}`,
    headers: AUTH_SERVICE_HEADERS,
    data: _data,
  };
};
