import {EndPoint} from "./endPoint";
import {AUTH_SERVICE_HEADERS} from "./headers";

export const bookingGet = (_queryParam) => {
  const url = `${EndPoint.BOOKING}/${  _queryParam}`;
  return {
    method: "GET",
    url: `${url}`,
    headers: AUTH_SERVICE_HEADERS,
  };
};
