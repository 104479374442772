import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import {Tabs} from "@mui/material";
import OpenEnquiryData from "./OpenEnquiryData";
import ClosedEnquiryData from "./ClosedEnquiryData";

export default function EnquiryViewer() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };


  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value} variant="fullWidth">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs  value={value} onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Open Enquiry" value="1" />
              <Tab label="Closed Enquiry" value="2" />
            </Tabs>
          </Box>
          <TabPanel value="1">
            <OpenEnquiryData/>
          </TabPanel>
          <TabPanel value="2">
            <ClosedEnquiryData/>
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
