import {EndPoint} from "./endPoint";
import {AUTH_SERVICE_HEADERS} from "./headers";

export const dailySheetGet = (_queryParam) => {
  const url = `${EndPoint.DAILYSHEET}/${  _queryParam}`;
  return {
    method: "GET",
    url: `${url}`,
    headers: AUTH_SERVICE_HEADERS,
  };
};

export const dailySheetDownload = (_queryParam) => {
  const url = `${EndPoint.DAILYSHEET}/${  _queryParam}/download`;
  return {
    method: "GET",
    url: `${url}`,
    headers: AUTH_SERVICE_HEADERS,
    responseType: 'blob'
  };
};
