import {
  Box,
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAxios} from "../commons/axios/UseAxios";
import {dailySheetDownload, dailySheetGet} from "../commons/apis/DailySheetGet";
import {DAILY_SHEET_VIEWER} from "../Config";

function DailySheetViewer() {

  const [dataInCSV, setDataInCSV] = useState("");
  const [valueDateRef, setValueDateRef] = useState(null)
  const {
    execute: getDailySheet,
    response: getDailySheetResponse,
    error: getDailySheetError,
  } = useAxios();

  const {
    execute: downloadDailySheet,
    response: downloadDailySheetResponse,
    error: downloadDailySheetError,
  } = useAxios();

  const [dialogOpen, setDialogOpen] = useState(false);
  function submitClicked() {
    const date = new Date(valueDateRef);
    date.setDate(date.getDate() + 1)
    const fetchDate = `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`;
    getDailySheet(dailySheetGet(fetchDate)).then(res => {})
  }

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleDownload = () => {
    const date = new Date(valueDateRef);
    date.setDate(date.getDate() + 1)
    const fetchDate = `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`
    downloadDailySheet(dailySheetDownload(fetchDate)).then();
    setDialogOpen(false);
  };

  useEffect(() => {
    if(downloadDailySheetResponse === undefined)
      return;
    const statusCode = downloadDailySheetResponse?.status;
    if (statusCode !== 200) {
      alert("Failed to download dailySheet")
    } else {
      const url = window.URL.createObjectURL(new Blob([downloadDailySheetResponse.data]))
      const link = document.createElement('a')
      link.href = url
      const date = new Date(valueDateRef);
      date.setDate(date.getDate() + 1)
      const fetchDate = `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`
      link.setAttribute('download', `${fetchDate}.csv`)
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  },[downloadDailySheetResponse]);

  useEffect(() => {
    if(getDailySheetResponse === undefined)
      return;
    const statusCode = getDailySheetResponse?.status;
    if (statusCode !== 200) {
      alert("Failed to fetch dailySheet")
      return
    }
    if (getDailySheetResponse?.data?.length === 0) {
      alert("No entry found for the date")
    } else{
      setDialogOpen(true)
    }

  },[getDailySheetResponse]);

  return (
    <>
      {/* first line */}
        <Grid item xs={12} sm={12}>
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format={"DD-MM-YYYY"}
              value={valueDateRef}
              onChange={(newValue) => setValueDateRef(newValue)}
              disableFuture
              label="Date"/>
          </LocalizationProvider>
          </Box>
        </Grid>
      <Grid item xs={12} sm={12}>
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Button
            variant='outlined'
            onClick={() => {
              submitClicked();
            }}
            sx={{mt: 3, ml: 1}}>
            Fetch
          </Button>
        </Box>
      </Grid>
      <Dialog
        fullScreen
        open={dialogOpen}
        scroll={'paper'}
        onClose={()=>setDialogOpen(false)}
      >
        <DialogTitle>
          <Box textAlign='center'>
            <Button
              onClick={handleDownload}
              variant='contained'>
              Download
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Table>
              <TableHead>
                <TableCell>Sr.No</TableCell>
                <TableCell>Particulars</TableCell>
                <TableCell>Credit</TableCell>
                <TableCell>Debit</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Ledger</TableCell>
              </TableHead>
              <tbody>
              {getDailySheetResponse?.data?.map(c=>{
                return <TableRow>
                  {Object.keys(c).map(k=><TableCell>{`${c[k]}`}</TableCell>)}
                </TableRow>
              })}
              </tbody>
            </Table>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDownload}
            variant='contained'>
            Download
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default DailySheetViewer