import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import CommonUtils from "../utils/CommonUtils";

const InvoiceOverlay = ({ isOpen, onClose, data}) => {

    const handlePrint = () => {
        // Print the dialog content
        window.print();
    };
    const containerStyle = {
        display: 'flex',
    };

    const boxStyle = {
        flex: 1,
        height: '200px',
        border: '0px solid #ccc',
        boxSizing: 'border-box',
    };

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            <DialogTitle>Estimate:  {data.hall} Palace</DialogTitle>
            <DialogContent>
                <div>
                    <div style={containerStyle}>
                        <div style={boxStyle}><p>Name : {data.name}</p>
                            <p>Address : {data.district}, {data.state}</p>
                        </div>
                        <div style={boxStyle} align={"right"}>
                            <p>Date: {CommonUtils.getDateFromDateArray(data.bookingMadeOn)}</p>
                            <p>Booking Id : {data.bookingId}</p>
                        </div>
                    </div>
                    <hr />
                    <table width={"100%"} >
                        <thead>
                        <tr>
                            <th width={"35%"} align={"left"}>Description</th>
                            <th width={"65%"} align={"right"}>Details</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td width={"35%"} align={"left"}>Booking From</td>
                                <td width={"65%"} align={"right"}>{CommonUtils.getDateFromDateArray(data.bookingFrom)}  11:00AM</td>
                            </tr>
                            <tr>
                                <td width={"35%"} align={"left"}>Booking To</td>
                                <td width={"65%"} align={"right"}>{CommonUtils.getDateFromDateArray(data.bookingTo)}  9:00AM</td>
                            </tr>
                            <tr>
                                <td width={"35%"} align={"left"}>Decoration</td>
                                <td width={"65%"} align={"right"}>{data.decorationDetails}</td>
                            </tr>
                            <tr>
                                <td width={"35%"} align={"left"}>Ac</td>
                                <td width={"65%"} align={"right"}>{data.isAcBooking ? "Yes" : "No(Non-AC)"}</td>
                            </tr>
                        </tbody>
                    </table>
                    <hr />
                    <h3>Payments</h3>
                    <table>
                        <thead>
                        <tr>
                            <th width={"25%"} align={"left"}>Date</th>
                            <th width={"25%"} align={"center"}>Amount</th>
                            <th width={"50%"} align={"right"}>Comment</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.payments?.map((item) => (
                            <tr key={item.id}>
                                <td width={"25%"} align={"left"}>{CommonUtils.getDateFromDateArray(item.dateOfPayment)}</td>
                                <td width={"25%"} align={"center"}>₹ {item.amount}</td>
                                <td width={"50%"} align={"right"}><small>{item.comments}</small></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <hr/>
                    <div align={"center"}>
                        <p>Total: ₹ {data.amount}</p>
                        <p>Payment Done: ₹ {data.totalPayment}</p>
                        <p>Pending Amount: ₹ {data.pendingPayment}</p>
                        <hr />
                        <p>Comment: {data.comments}</p>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handlePrint} variant="contained" color="primary">
                    Print
                </Button>
                <Button onClick={onClose} variant="contained" color="secondary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InvoiceOverlay;
