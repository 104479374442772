import {EndPoint} from "./endPoint";
import {AUTH_SERVICE_HEADERS} from "./headers";

export const enquiryOpen = () => {
  return {
    method: "GET",
    url: `${EndPoint.ENQUIRY}` + '/get-open',
    headers: AUTH_SERVICE_HEADERS,
  };
};


export const enquiryClosed = () => {
  return {
    method: "GET",
    url: `${EndPoint.ENQUIRY}` + '/get-closed',
    headers: AUTH_SERVICE_HEADERS,
  };
};
