import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Typography,
  Divider,
  CircularProgress, Backdrop
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import {useAxios} from "../../../commons/axios/UseAxios";
import Authorization from "../../../commons/token/authorization";
import {userLogin} from "../../../commons/apis/Login";
import CommonUtils from "../../../utils/CommonUtils";
import * as RoutePath from "../../../Config";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const {
    execute: executeUserLogin,
    response: userLoginResponse,
    error: userLoginError,
    loading : apiLoading
  } = useAxios();

  const [showPassword, setShowPassword] = useState(false);
  const [loginFailure, setLoginFailure] = useState(false);
  const setLoginFailureToFalse = () => setLoginFailure(false);
  const history = useNavigate();
  const indicatorSize = 160;

  useEffect(() => {
    if (CommonUtils.isEmptyUndefinedOrNull(Authorization.getAccessToken())) {
      Authorization.clearAccessToken();
    }

    if (userLoginResponse === undefined && userLoginError === undefined) return;
    if (userLoginResponse !== undefined) {
      const statusCode = userLoginResponse?.status;
      if (statusCode === 200) {
        const token = userLoginResponse?.data?.token;
        Authorization.setAccessToken(token);
        history(RoutePath.CALENDAR);
      }
    } else if (userLoginError !== undefined) {
      setLoginFailure(true);
    }
  }, [userLoginResponse, userLoginError]);

  const handleClick = (event) => {
    setLoginFailureToFalse();
    event.preventDefault();
    const data = {
      username: username.value,
      password: password.value,
    };
    Authorization.clearAccessToken();
    executeUserLogin(userLogin(data)).then();
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="username" label="Username" id="username" />

        <TextField
          name="password"
          label="Password"
          id="password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
       <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }} />
       </Divider>
      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
      <div  style={{ position: 'relative', zIndex: '2' }}>
        {apiLoading && (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}>
              <CircularProgress
                  size={indicatorSize}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: `${-indicatorSize/2}px`,
                    marginLeft: `${-indicatorSize/2}px`,
                    color: 'gold',
                  }}
              />
              <h3>Logging in</h3>
            </Backdrop>
        )}

      </div>
    </>
  );
}
