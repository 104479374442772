import qs from "qs";
import { EndPoint } from "./endPoint";
import { AUTH_SERVICE_HEADERS } from "./headers";

export const enquiry = (_data) => {
  qs.stringify(_data);
  return {
    method: "POST",
    url: `${EndPoint.ENQUIRY}`,
    headers: AUTH_SERVICE_HEADERS,
    data: _data,
  };
};

export const enquiryGet = (_queryParam) => {
  const url = `${EndPoint.ENQUIRY}/${_queryParam}`;
  return {
    method: "GET",
    url: `${url}`,
    headers: AUTH_SERVICE_HEADERS,
  };
};

export const enquiryClose = (_data,id) => {
  const url = `${EndPoint.ENQUIRY}/close-enquiry/${id}`;
  return {
    method: "POST",
    url: `${url}`,
    headers: AUTH_SERVICE_HEADERS,
    data: _data,
  };
};

export const updateEnquiry = (_data,id) => {
  const url = `${EndPoint.ENQUIRY}/update/${id}`;
  return {
    method: "POST",
    url: `${url}`,
    headers: AUTH_SERVICE_HEADERS,
    data: _data,
  };
}