class CommonUtils {
  static isEmptyUndefinedOrNull(StrValue) {
    if (StrValue) return false;
    return true;
  }

  static getDateOnlyFromLocalDate(date) {
    return CommonUtils.getDateOnlyFromLocalDateUsingDelimiter(date, "/");
  }

  static getDateOnlyFromLocalDateUsingDelimiter(date, delimiter) {
    return `${date.getDate()} ${delimiter} ${date.getMonth()} ${delimiter} ${date.getFullYear()}`;
  }

  static getMinimumDate() {
    return new Date(-8640000000000000);
  }

  static getMaximumDate() {
    return new Date(8640000000000000);
  }

  static getDateTimeFromEpoc(input) {
    try{
      const ISTOffset = 330;

      return new Date( (input * 1000) + (ISTOffset)*60000).toISOString().split("T")[0] + " " +
        new Date( (input * 1000) + (ISTOffset)*60000).toISOString().split("T")[1].split(".")[0]
    }catch (e) {
      return null;
    }
  }

  static getEndPoint(){
    return process.env.REACT_BACKEND_ENDPOINT;
  }

  static getDateFromEpoc(input) {
    try{
      const ISTOffset = 330;
      return new Date( (input * 1000) + (ISTOffset)*60000).toISOString().split("T")[0]
    }catch (e) {
      return null;
    }
  }

  static getDateFromDateTime(dateTime) {
    if (CommonUtils.isEmptyUndefinedOrNull(dateTime) || dateTime.length < 3) return undefined;
    const day = dateTime[2];
    //month number starts from 0
    const month = dateTime[1] - 1;
    const year = dateTime[0];
    return new Date(Date.UTC(year, month, day));
  }


  static getDateFromTimeFromInstant(instant) {
    const date = new Date(instant*1000);
// Helper function to add leading zeros to single-digit numbers
    const addLeadingZero = (number) => {
      return number < 10 ? `0${number}` : number.toString();
    };
// Get the date components
    const day = addLeadingZero(date.getDate());
    const month = addLeadingZero(date.getMonth() + 1); // Months are zero-based, so we add 1
    const year = date.getFullYear();
    const hours = addLeadingZero(date.getHours());
    const minutes = addLeadingZero(date.getMinutes());
// Form the formatted string
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }

  static getDateFromFromInstant(instant) {
    const date = new Date(instant*1000);
// Helper function to add leading zeros to single-digit numbers
    const addLeadingZero = (number) => {
      return number < 10 ? `0${number}` : number.toString();
    };
// Get the date components
    const day = addLeadingZero(date.getDate());
    const month = addLeadingZero(date.getMonth() + 1); // Months are zero-based, so we add 1
    const year = date.getFullYear();
// Form the formatted string
    return `${day}-${month}-${year}`;
  }

  static getDateFromDateArray(dateArr) {
    if (CommonUtils.isEmptyUndefinedOrNull(dateArr) || dateArr.length < 3) return undefined;
      const day = dateArr[2];
      const month = dateArr[1] // Months are zero-based, so we add 1
      const year = dateArr[0];
// Form the formatted string
      return `${day}-${month}-${year}`;
// Get the date components

  }
}

export default CommonUtils;
