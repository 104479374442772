import qs from "qs";
import { EndPoint } from "./endPoint";
import { AUTH_SERVICE_HEADERS } from "./headers";

export const dashboard = (_data) => {
  qs.stringify(_data);
  return {
    method: "GET",
    url: `${EndPoint.DASHBOARD}`,
    headers: AUTH_SERVICE_HEADERS,
  };
};
