import {useEffect, useRef, useState} from 'react';
// @mui
import {Box, Button, Checkbox, Grid, InputAdornment, TextField} from '@mui/material';

import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {useAxios} from "../commons/axios/UseAxios";
import {booking} from "../commons/apis/Booking";
import InvoiceOverlay from "../overlay/InvoiceOverlay";

// ----------------------------------------------------------------------


export default function BookingPage() {
  const valueBookingNameRef = useRef('')
  const [valueBookingStartDateRef, setValueBookingStartDateRef] = useState(null)
  const [valueBookingEndDateRef, setValueBookingEndDateRef] = useState(null)
  const valueBookingHallRef = useRef('')
  const [valueBookingDateRef, setBookingValueDateRef] = useState(new dayjs())

  const valueBookingCustomerStateRef = useRef('')
  const valueBookingCustomerDistrictRef = useRef('')
  const valueBookingCustomerMobileNumberRef = useRef('')
  const valueBookingAlternateCustomerMobileNumberRef = useRef('')

  const valueBookingAmount = useRef('')
  const valueBookingAmountAdvance = useRef('')
  const valueEventTypeRef = useRef('')
  const valueSourceRef = useRef('')
  const [valueAcBooking, setValueAcBooking]  = useState(false)

  const valueDecorationRef = useRef('')
  const valueCommentRef = useRef('')

  const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);

  const {
    execute: executeBookingSubmit,
    response: bookingResponse,
    error: bookingError,
  } = useAxios();

  const jharkhandDistricts = ['','Bokaro','Chatra','Deoghar','Dhanbad','Dumka','East Singhbhum','Garhwa','Giridih','Godda','Gumla','Hazaribagh','Jamtara','Khunti','Koderma','Latehar','Lohardaga','Pakur','Palamu','Ramgarh','Ranchi','Sahebganj','Seraikela Kharsawan','Simdega','West Singhbhum'];
  const biharDistricts = ['','Araria','Arwal','Aurangabad','Banka','Begusarai','Bhagalpur','Bhojpur','Buxar','Darbhanga','East Champaran','Gaya','Gopalganj','Jamui','Jehanabad','Kaimur','Katihar','Khagaria','Kishanganj','Lakhisarai','Madhepura','Madhubani','Munger','Muzaffarpur','Nalanda','Nawada','Patna','Purnia','Rohtas','Saharsa','Samastipur','Saran','Sheikhpura','Sheohar','Sitamarhi','Siwan','Supaul','Vaishali','West Champaran'];
  const [districtDropDown, setDistrictDropDown] = useState(null);

  const {
    execute: getBookingDetails,
    response: getBookingDetailsResponse,
    error: getBookingDetailsError,
  } = useAxios();

  const handleCloseInvoice = () => {
    setIsInvoiceOpen(false);
    window.location.reload();
  };

  const customLocale = {
    timeZone: 'Asia/Kolkata',
  };

  function validate(data) {
    if (data.name === undefined || data.name === "") {
      alert("Name can not be empty")
      return false;
    }
    if(data.bookingFrom === "Invalid date" || data.bookingTo === "Invalid date" || data.bookingFrom > data.bookingTo){
      alert("Booking date not valid")
      return false;
    }
    if(data.customerPhoneNumber === "" || data.district === "" || data.state === ""){
      alert("Customer address/Mobile details missing")
      return false;
    }

    if(data.customerPhoneNumber === data.alternatePhoneNumber){
      alert("Enter different alternate mobile number")
      return false;
    }
    if(data.source === ""){
      alert("Enquiry Source is missing")
      return false;
    }
    if(data.amount === "" ){
      alert("Amount  cannot be empty")
      return false;
    }
    if(data.advance === "" ){
      alert("Advance Amount cannot be empty")
      return false;
    }


    if(data.amount > 999999 || data.amount < 0){
      alert("Amount out of range")
      return false;
    }
    if(data.advance > 999999 || data.advance < 0){
      alert("Advance Amount out of range")
      return false;
    }
    if(data.eventType === "" ){
      alert("Event type cannot be empty")
      return false;
    }
    if(data.hall === "" ){
      alert("Hall cannot be be empty")
      return false;
    }
    if(data.decorationDetails === '' || data.decorationDetails.length < 10){
      alert("Please add decoration in detail")
      return false;
    }
    if(data.isAcBooking === false ){
      alert("Non Ac booking")
    }
    return true;
  }
  const submitClicked = () => {

    // Doing this because it takes date at GMT time hence it shows one day less
    const fromDate = new Date(valueBookingStartDateRef);
    fromDate.setDate(fromDate.getDate() +1)
    const toDate = new Date(valueBookingEndDateRef);
    toDate.setDate(toDate.getDate() +1)
    const bookingMadeOn = new Date(valueBookingDateRef);
    bookingMadeOn.setDate(bookingMadeOn.getDate() + 1);

    const data = {
      name: valueBookingNameRef.current.value,
      bookingFrom: fromDate,
      bookingTo: toDate,
      hall: valueBookingHallRef.current.value,
      eventType: valueEventTypeRef.current.value,
      district: valueBookingCustomerDistrictRef.current.value,
      state: valueBookingCustomerStateRef.current.value,
      phoneNumber: valueBookingCustomerMobileNumberRef.current.value,
      alternatePhoneNumber: valueBookingAlternateCustomerMobileNumberRef.current.value,
      source: valueSourceRef.current.value,
      comments: valueCommentRef.current.value,
      advance: valueBookingAmountAdvance.current.value,
      amount: valueBookingAmount.current.value,
      isAcBooking: valueAcBooking,
      decorationDetails: valueDecorationRef.current.value,
      bookingMadeOn : bookingMadeOn
    };

    if(validate(data)){
      executeBookingSubmit(booking(data)).then();
    }
  }

  useEffect( () => {
    const statusCode = bookingResponse?.status;
    if (statusCode === 200) {
      alert("Booking Registered Successfully")
      setIsInvoiceOpen(true);
    } else if (bookingError !== undefined && bookingError?.code === "ERR_BAD_REQUEST") {
      alert(bookingError.response.data.errors)
    }
  }, [bookingResponse,bookingError]);

  return (
    <>
      {/* first line */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            label='Name'
            name='Name'
            placeholder='Customer name'
            inputRef={valueBookingNameRef}
            required
          />
        </Grid>
        {/* second line */}
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format={"DD-MM-YYYY"}
              disablePast
              value={valueBookingStartDateRef}
              onChange={(newValue) => setValueBookingStartDateRef(newValue)}
              locale={customLocale}
              label="Check-in"/>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format={"DD-MM-YYYY"}
              minDate={valueBookingStartDateRef}
              value={valueBookingEndDateRef}
              onChange={(newValue) => setValueBookingEndDateRef(newValue)}
              locale={customLocale}
              disablePast label="Check-out"/>
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            select
            SelectProps={{
              native: true
            }}
            label='Hall'
            name='hall'
            inputRef={valueBookingHallRef}
            required
          >
            <option value=''/>
            <option value='VIRAT'>Virat Palace</option>
            <option value='JDP'>Jai Durga Palace</option>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format={"DD-MM-YYYY"}
              value={valueBookingDateRef}
              onChange={(newValue) => setBookingValueDateRef(newValue)}
              disableFuture
              label="Booking Date"/>
          </LocalizationProvider>
        </Grid>

        {/* third line */}

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            select
            SelectProps={{
              native: true
            }}
            label='Customer State'
            name='customerState'
            inputRef={valueBookingCustomerStateRef}
            onChange={(value) =>{
              if(valueBookingCustomerStateRef.current.value === "Jharkhand")
                setDistrictDropDown(jharkhandDistricts);
              else if(valueBookingCustomerStateRef.current.value === "Bihar")
                setDistrictDropDown(biharDistricts);
              else
                setDistrictDropDown(['Others'])
            }}
          >
            <option value=''/>
            <option value="Jharkhand">Jharkhand</option>
            <option value="Bihar">Bihar</option>
            <option value="Others">Others</option>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            select
            SelectProps={{
              native: true
            }}
            label='Customer District'
            name='customerDistrict'
            inputRef={valueBookingCustomerDistrictRef}
          >
            {districtDropDown?.map(option => {
              return (
                  <option value={option}>
                    {option}
                  </option>
              );
            })}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label='Customer Mobile number'
            name='customerMobileNumber'
            placeholder='Customer Mobile Number'
            inputRef={valueBookingCustomerMobileNumberRef}
            type="number"
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label='Alternate Mobile number'
            name='alternateMobileNumber'
            placeholder='Alternate Mobile Number'
            inputRef={valueBookingAlternateCustomerMobileNumberRef}
            type="number"
          />
        </Grid>

        {/* fifth line */}

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label='Amount'
            name='Amount'
            placeholder='Amount'
            inputRef={valueBookingAmount}
            required
            type="number"
            InputProps={{
              startAdornment: <InputAdornment position="start">₹</InputAdornment>,
              inputMode: 'numeric', pattern: '[0-9]*',
              max: 500000,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label='Advance Amount'
            name='Advance Amount'
            placeholder='Advanced Amount'
            inputRef={valueBookingAmountAdvance}
            required

            type="number"
            InputProps={{
              startAdornment: <InputAdornment position="start">₹</InputAdornment>,
              inputMode: 'numeric', pattern: '[0-9]{6}',
              max: {valueBookingAmount}
            }}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Checkbox
              value={valueAcBooking}
              onChange={(value) =>{
                setValueAcBooking(value?.target?.checked)
              }}
              required/> Ac Booking
        </Grid>

        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            select
            SelectProps={{
              native: true
            }}
            label='Event Type'
            name='eventType'
            inputRef={valueEventTypeRef}
          >
            <option value=''/>
            <option value='MARRIAGE'>MARRIAGE</option>
            <option value='BIRTHDAY'>BIRTHDAY</option>
            <option value='UPANAYANA'>UPANAYANA</option>
            <option value='OTHERS'>OTHERS</option>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            select
            SelectProps={{
              native: true
            }}
            label='Source'
            name='source'
            inputRef={valueSourceRef}
          >
            <option value=''/>
            <option value='FACEBOOK'>FACEBOOK</option>
            <option value='JD'>JD</option>
            <option value='FRIEND'>FRIEND</option>
            <option value='SELF'>SELF</option>
            <option value='GOOGLE'>GOOGLE</option>
            <option value='FAMILY'>FAMILY</option>
            <option value='OTHERS'>OTHERS</option>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
              fullWidth
              label='Decoration Details'
              name='decorationDetails'
              placeholder='Package/Hi-Fi'
              multiline
              rows={3}
              inputRef={valueDecorationRef}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            label='Comment'
            name='comment'
            placeholder='Comment'
            multiline
            rows={3}
            inputRef={valueCommentRef}
          />
        </Grid>

      </Grid>


      <Grid item xs={12} sm={6}>
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Button
            variant='outlined'
            onClick={() => {
              submitClicked();
            }}
            sx={{mt: 3, ml: 1}}>
            Submit Booking
          </Button>
        </Box>
      </Grid>

      {isInvoiceOpen && (
          <InvoiceOverlay isOpen={isInvoiceOpen} onClose={handleCloseInvoice}  data={bookingResponse.data}/>
      )}
    </>
  );
}
