import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import {Button, Grid, TableHead} from "@mui/material";
import TextField from "@mui/material/TextField";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {useEffect, useRef, useState} from "react";
import {allLedgerGet} from "../commons/apis/AllLedgerGet";
import {getLedgerById, getLedgerByIdAPI} from "../commons/apis/LedgerGetById";
import {useAxios} from "../commons/axios/UseAxios";
import {dailySheetDownload} from "../commons/apis/DailySheetGet";
import dayjs from "dayjs";

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}
export default function LedgerData() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = React.useState([]);
    const [valueStartDateRef, setValueStartDateRef] = useState(null)
    const [valueEndDateRef, setValueEndDateRef] = useState(new dayjs())
    const valueLedgerId = useRef('')
    const [currentLedgerName, setCurrentLedgerName] = useState('')
    const [currentLedgerActive, setCurrentLedgerActive] = useState('')

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const fetchLedger = () => {
        const fromDate = new Date(valueStartDateRef);
        fromDate.setDate(fromDate.getDate() +1)
        const toDate = new Date(valueEndDateRef);
        toDate.setDate(toDate.getDate() +1)

        const data = {
            id: valueLedgerId.current.value,
            fromDate: fromDate,
            toDate: toDate,
        };

        executeLedgerById(getLedgerByIdAPI(data)).then();
    };

    const {
        execute: executeLedgerById,
        response: getLedgerById,
        error: getLedgerByIdError,
    } = useAxios();

    // useEffect((data) => {
    //     executeLedgerById(data).then();
    // },[]);

    useEffect(() => {
        if(getLedgerById === undefined)
            return;
        const statusCode = getLedgerById?.status;
        if (statusCode !== 200) {
            alert("Failed to fetch all ledger detail")
            return
        }
        //ledger data
        setRows(getLedgerById.data?.ledgerDataResponseDtos)
        setCurrentLedgerName(getLedgerById.data?.ledgerResponseDto?.name)
        setCurrentLedgerActive(getLedgerById.data?.ledgerResponseDto?.isActive ? "Yes" : "No")
    },[getLedgerById])

    return (
        <>
            <div><Grid item xs={12} sm={12}>
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': {m: 1, width: '25ch'},
                        display: 'flex', justifyContent: 'center'
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={valueStartDateRef}
                            onChange={(newValue) => setValueStartDateRef(newValue)}
                        />
                    </LocalizationProvider>
                    <TextField id="ledger-id" label="Ledger Id" variant="outlined"
                               name='Ledger Id'
                               placeholder='Ledger Id'
                               type="number"
                               inputRef={valueLedgerId}/>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={valueEndDateRef}
                            onChange={(newValue) => setValueEndDateRef(newValue)}/>
                    </LocalizationProvider>
                </Box>
            </Grid>
                <Grid item xs={12} sm={12}>
                    <Box sx={{
                        '& > :not(style)': {m: 1, width: '25ch'},
                        display: 'flex', justifyContent: 'center'
                    }}>
                        <Button variant="outlined" onClick={fetchLedger}>Fetch</Button>
                    </Box>
                </Grid>
            </div>
            <div>
                <h1>Ledger : {currentLedgerName}(Active : {currentLedgerActive})</h1>
            </div>

            <TableContainer component={Paper}>
                <Table sx={{minWidth: 500}} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>

                            <TableCell>
                                {"Date"}
                            </TableCell>
                            <TableCell>
                                {"Particulars"}
                            </TableCell>
                            <TableCell>
                                {"Credit"}
                            </TableCell>
                            <TableCell>
                                {"Debit"}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : rows
                        ).map((row,index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {row.date}
                                </TableCell>
                                <TableCell>
                                    {row.particulars}
                                </TableCell>
                                <TableCell>
                                    {row.credit}
                                </TableCell>
                                <TableCell>
                                    {row.debit}
                                </TableCell>
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{height: 53 * emptyRows}}>
                                <TableCell colSpan={6}/>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, {label: 'All', value: -1}]}
                                colSpan={3}
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>

    );
}