import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import {Button, TableHead} from "@mui/material";
import {useAxios} from "../commons/axios/UseAxios";
import {useEffect, useState} from "react";
import {enquiryOpen} from "../commons/apis/EnquiryList";
import CommonUtils from "../utils/CommonUtils";
import EnquiryOverlay from "../overlay/EnquiryOverlay";

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}


export default function OpenEnquiryData() {
    const [page, setPage] = React.useState(0);
    const [rows, setRows] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState(null);
    const {
        execute: getEnquiryOpen,
        response: enquiryOpenResponse,
        error: getEnquiryOpenError,
    } = useAxios();
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const [openEnquiry, setOpenEnquiry] = useState(null);
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    const handleRowClick = (row) => {
        let filteredEnquiry = openEnquiry.filter((object) => object.id === row ? object : null)
        if(filteredEnquiry !== null  && filteredEnquiry.length > 0){
            filteredEnquiry = filteredEnquiry[0]
        }
        setDialogData(filteredEnquiry);
        setDialogOpen(true);
    };

    useEffect(() => {
        getEnquiryOpen(enquiryOpen()).then();
    },[]);

    useEffect(() => {
        if(enquiryOpenResponse === undefined || enquiryOpenResponse?.status === undefined)
            return;
        const statusCode = enquiryOpenResponse?.status;

        if (statusCode !== 200) {
            alert("Failed to fetch all open enquiries detail")
            return
        }
        //enquiry data
        setOpenEnquiry(enquiryOpenResponse.data)
        setRows(openEnquiry?.length)
    },[enquiryOpenResponse])

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: 160 }}>Name,Address</TableCell>
                            <TableCell style={{ width: 100 }} align="right">Enquiry Data</TableCell>
                            <TableCell style={{ width: 240 }} align="right">Booking Date</TableCell>
                            <TableCell style={{ width: 100 }} align="right">Enquiry Status</TableCell>
                            <TableCell style={{ width: 320 }} align="right">Comment</TableCell>
                            <TableCell style={{ width: 100 }} align="right">MobileNumber</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? openEnquiry?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : openEnquiry)?.map((row) => (
                            <TableRow key={row.id} onClick={() => handleRowClick(row.id)}>
                                <TableCell style={{ width: 160 }} component="th" scope="row">
                                    {row.name}, {row.callerDistrict}
                                </TableCell>
                                <TableCell style={{ width: 100 }} align="right">
                                    {CommonUtils.getDateFromDateArray(row.dateOfEnquiry)}
                                </TableCell>
                                <TableCell style={{ width: 240 }} align="right">
                                    {CommonUtils.getDateFromDateArray(row.bookingFrom)} to {CommonUtils.getDateFromDateArray(row.bookingTo)}
                                </TableCell>
                                <TableCell style={{ width: 100 }} align="right">
                                    {row.enquiryStatusEnum}
                                </TableCell>
                                <TableCell style={{ width: 320 }} align="right">
                                    {row.comments}
                                </TableCell>
                                <TableCell style={{ width: 100 }} align="right">
                                    {row.customerPhoneNumber}
                                </TableCell>
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={3}
                                count={rows === undefined ? 0 : (rows)}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            {dialogOpen && (
                <EnquiryOverlay isOpen={dialogOpen} onClose={handleClose}  data={dialogData}/>
            )}
        </>


    );
}