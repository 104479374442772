import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import DailySheet from './pages/DailySheet';
import EnquiryPage from './pages/EnquiryPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import BookingPage from './pages/BookingPage';
import DashboardAppPage from './pages/DashboardAppPage';
import DailySheetViewer from "./pages/DailySheetViewer";
import BookingViewer from "./pages/BookingViewer";
import StockManagement from "./pages/StockManagement";
import LedgerViewer from "./pages/LedgerViewer";
import EnquiryViewer from "./pages/EnquiryViewer";

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'enquiry', element: <EnquiryPage /> },
        { path: 'enquiry-viewer', element: <EnquiryViewer /> },
        { path: 'booking', element: <BookingPage /> },
        { path: 'daily-sheet', element: <DailySheet /> },
        { path: 'daily-sheet-viewer', element: <DailySheetViewer /> },
        { path: 'booking-viewer', element: <BookingViewer /> },
        { path: 'ledger', element: <LedgerViewer /> },
        { path: 'stock-management', element: <StockManagement /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
