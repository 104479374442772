import {EndPoint} from "./endPoint";
import {AUTH_SERVICE_HEADERS} from "./headers";

export const ledgerGet = (_pathData) => {
  const url = `${EndPoint.LEDGER  }/${  _pathData}`;
  return {
    method: "GET",
    url: `${url}`,
    headers: AUTH_SERVICE_HEADERS
  };
};
