import {EndPoint} from "./endPoint";
import {AUTH_SERVICE_HEADERS} from "./headers";

export const allLedgerGet = () => {
  const url = `${EndPoint.LEDGER}/fetch-all-non-booking`;
  return {
    method: "GET",
    url: `${url}`,
    headers: AUTH_SERVICE_HEADERS,
  };
};
