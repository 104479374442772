import qs from "qs";
import {EndPoint} from "./endPoint";
import {AUTH_SERVICE_HEADERS} from "./headers";

export const dailySheetSave = (_data) => {
  qs.stringify(_data);
  return {
    method: "POST",
    url: `${EndPoint.DAILYSHEET}`,
    headers: AUTH_SERVICE_HEADERS,
    data: _data,
  };
};
