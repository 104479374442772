import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import {Tabs} from "@mui/material";
import AllLedgerData from "./AllLedgerData";
import LedgerData from "./LedgerData";
import CreateLedger from "./CreateLedger";

export default function LedgerViewer() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };


  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value} variant="fullWidth">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs  value={value} onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="All Ledger(Non Booking)" value="1" />
              <Tab label="Fetch Ledger" value="2" />
              <Tab label="Create Ledger(Admin)" value="3" />
            </Tabs>
          </Box>
          <TabPanel value="1">
            <AllLedgerData/>
          </TabPanel>
          <TabPanel value="2">
            <LedgerData/>
          </TabPanel>
          <TabPanel value="3">
            <CreateLedger/>
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
