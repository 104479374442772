import {useTheme} from '@mui/material/styles';
import React, {useEffect, useMemo, useState} from "react";
import {Calendar, momentLocalizer} from "react-big-calendar";
import moment from "moment";
import CommonUtils from "../utils/CommonUtils";
import {useAxios} from "../commons/axios/UseAxios";
import {dashboard} from "../commons/apis/Dashboard";
import "react-big-calendar/lib/css/react-big-calendar.css";
import InvoiceOverlay from "../overlay/InvoiceOverlay";
import {bookingGet} from "../commons/apis/BookingGet";
import './../css/CalendarStyles.css';
import {Backdrop, CircularProgress} from "@mui/material";

const localise = momentLocalizer(moment);
export const viratBackGroundColor = "#f00";
export const viratTextColor = "#fff";

export const jdBackGroundColor = "#ccc";
export const jdTextColor = "#fff";

export const thakurPrasadBackGroundColor = "#e1cf0b";
export const thakurPrasadTextColor = "#000";

export const vishwaVidyalayBackGroundColor = "#47aa00";
export const vishwaVidyalayTextColor = "#000";

export const mahaveerBackGroundColor = "#ff00f2";
export const mahaveerTextColor = "#000";


const calendar = [];

export default function DashboardAppPage() {
  const theme = useTheme();

  const data = {};
  const {execute: executeDashboard, response: dashboardResponse, loading : apiLoading} = useAxios();
  const {
    execute: getBookingDetails,
    response: getBookingDetailsResponse,
    error: getBookingDetailsError,
  } = useAxios();
  const [dialogOpen, setDialogOpen] = useState(false);


  const handleClose = () => {
    setDialogOpen(false);
  };
  const indicatorSize = 160;

  useEffect(() => {
    const statusCode = dashboardResponse?.status;
    if (statusCode === 200) {
      calendar.length = 0;

      const bookingList = dashboardResponse?.data?.bookedResponse;
      bookingList.map((bookedResponse) => {
        if (bookedResponse.hall === "VIRAT") {
          calendar.push({
            allDay: true,
            backgroundColor: `${viratBackGroundColor}`,
            color: `${viratTextColor}`,
            end: `${bookedResponse.bookingDate}`,
            start: `${bookedResponse.bookingDate}`,
            title: `${bookedResponse.name}`.concat(" (", `${bookedResponse.unifiedBookingId}`, ")"),
            id: `${bookedResponse.unifiedBookingId}`,
            lagan: false
          });
        } else if (bookedResponse.hall === "JDP") {
          calendar.push({
            allDay: true,
            backgroundColor: `${jdBackGroundColor}`,
            color: `${jdTextColor}`,
            end: `${bookedResponse.bookingDate}`,
            start: `${bookedResponse.bookingDate}`,
            title: `${bookedResponse.name}`.concat(" (", `${bookedResponse.unifiedBookingId}`, ")"),
            id: `${bookedResponse.unifiedBookingId}`,
            lagan: false
          });
        }
      });

      const laganList = dashboardResponse?.data?.lagan;
      laganList.map((laganResponse) => {
        let laganText = "";
        if (laganResponse.fullNightLagan)
          laganText = "(Full Night)";

        if (laganResponse.panchange === "MP") {
          calendar.push({
            allDay: true,
            backgroundColor: `${mahaveerBackGroundColor}`,
            color: `${mahaveerTextColor}`,
            end: `${laganResponse.date}`,
            start: `${laganResponse.date}`,
            title: "Mahaveer".concat(" ", `${laganText}`, ""),
            lagan: true
          });
        } else if (laganResponse.panchange === "TP") {

          calendar.push({
            allDay: true,
            backgroundColor: `${thakurPrasadBackGroundColor}`,
            color: `${thakurPrasadTextColor}`,
            end: `${laganResponse.date}`,
            start: `${laganResponse.date}`,
            title: "Thakur Prasad".concat(" ", `${laganText}`, ""),
            lagan: true
          });
        } else if (laganResponse.panchange === "VP") {
          calendar.push({
            allDay: true,
            backgroundColor: `${vishwaVidyalayBackGroundColor}`,
            color: `${vishwaVidyalayTextColor}`,
            end: `${laganResponse.date}`,
            start: `${laganResponse.date}`,
            title: "Vishwa Vidyalay".concat(" ", `${laganText}`, ""),
            lagan: true
          });
        }
      });
    }
  }, [dashboardResponse]);

  const {views} = useMemo(
      () => ({
        views: {
          month: true
        },
      }),
      []
  );

  const eventClicked = (event) => {
    if (event.lagan)
      return;

    const listItems = dashboardResponse?.data?.bookedResponse;
    let bookingFrom = CommonUtils.getMaximumDate();
    let name = "";
    let hall = "";
    listItems.map((bookedResponse) => {
      if (bookedResponse.unifiedBookingId === event?.id) {
        const date = CommonUtils.getDateFromDateTime(bookedResponse.bookingDate);
        if (date < bookingFrom) {
          bookingFrom = date;
        }
        name = bookedResponse.name;
        hall = bookedResponse.hall;
      }
    });
    const fetchParam = `${hall}/${bookingFrom.getUTCDate()}/${bookingFrom.getUTCMonth() + 1}/${bookingFrom.getUTCFullYear()}`;
    getBookingDetails(bookingGet(fetchParam)).then()
  };

  useEffect(() => {
    executeDashboard(dashboard(data)).then();
  }, []);

  useEffect(() => {
    if (getBookingDetailsResponse === undefined)
      return;
    const statusCode = getBookingDetailsResponse?.status;
    if (statusCode !== 200) {
      alert("Failed to fetch booking detail")
      return
    }
    setDialogOpen(true)
  }, [getBookingDetailsResponse])

  return (
      <>
        <div>
          <Calendar
              onSelectEvent={(e) => {
                eventClicked(e);
              }}
              localizer={localise}
              events={calendar}
              // startAccessor="start"
              // endAccessor="end"
              style={{height: 1200}}
              views={views}
              eventPropGetter={(myEventsList) => {
                const backgroundColor = myEventsList.backgroundColor ? myEventsList.backgroundColor : 'blue';
                const color = myEventsList.color ? myEventsList.color : 'blue';
                const height = 20;
                const fontSize = '13px'
                return {style: {backgroundColor, color, height,fontSize}}
              }}
          />
          <div style={{width: "30%", marginLeft: "35%"}}>
            <p style={{
              color: `${viratTextColor}`,
              background: `${viratBackGroundColor}`,
              textAlign: "center",
              width: "100%"
            }}>Virat Palace</p>
            <p style={{
              color: `${jdTextColor}`,
              background: `${jdBackGroundColor}`,
              textAlign: "center",
              width: "100%"
            }}>Jai Durga Palace</p>
            <p style={{
              color: `${mahaveerTextColor}`,
              background: `${mahaveerBackGroundColor}`,
              textAlign: "center",
              width: "100%"
            }}>Mahaveer Panchang</p>
            <p style={{
              color: `${thakurPrasadTextColor}`,
              background: `${thakurPrasadBackGroundColor}`,
              textAlign: "center",
              width: "100%"
            }}>Thakur Prasad</p>
            <p style={{
              color: `${vishwaVidyalayTextColor}`,
              background: `${vishwaVidyalayBackGroundColor}`,
              textAlign: "center",
              width: "100%"
            }}>Vishwa Vidyalay Panchang</p>
          </div>
        </div>
        {dialogOpen && (
            <InvoiceOverlay isOpen={dialogOpen} onClose={handleClose} data={getBookingDetailsResponse.data}/>
        )}

        <div  style={{ position: 'relative', zIndex: '2' }}>
          {apiLoading && (
              <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={open}>
                <CircularProgress
                    size={indicatorSize}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: `${-indicatorSize/2}px`,
                      marginLeft: `${-indicatorSize/2}px`,
                      color: 'gold',
                    }}
                />
                <h3>Loading Data</h3>
              </Backdrop>
          )}

        </div>
      </>
  )
}