import * as React from 'react';
import {useEffect, useRef} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {Button, Grid} from "@mui/material";
import {ledgerCreate} from "../commons/apis/LedgerCreate";
import {useAxios} from "../commons/axios/UseAxios";

export default function CreateLedger() {

    const createLedger = () => {
        const data = {
            name: valueNameRef.current.value,
        };
        executeLedgerCreate(ledgerCreate(data)).then();
    };
    const valueNameRef = useRef('')

    const {
        execute: executeLedgerCreate,
        response: getLedgerCreateResponse,
        error: getLedgerCreateError,
    } = useAxios();

    useEffect(() => {
        const statusCode = getLedgerCreateResponse?.status;
        if (statusCode === 200) {
            alert("Ledger Created")
            window.location.reload();
        } else if (getLedgerCreateError !== undefined && getLedgerCreateError?.code === "ERR_BAD_REQUEST") {
            alert(getLedgerCreateError.response.data.errors)
        }
    }, [getLedgerCreateResponse, getLedgerCreateError]);

    return (
        <>
            <Grid item xs={12} sm={12}>
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': {m: 1, width: '25ch'},
                        display: 'flex', justifyContent: 'center'
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField id="ledger-name" label="Ledger Name" variant="outlined" inputRef={valueNameRef}/>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Box sx={{
                    '& > :not(style)': {m: 1, width: '25ch'},
                    display: 'flex', justifyContent: 'center'
                }}>
                    <Button variant="outlined" onClick={createLedger}>Submit</Button>
                </Box>
            </Grid>
        </>

    );
}