import { constants } from "../../Constants";

class Authorization {
  constructor() {
    this.accessToken = null;
  }

  getAccessToken() {
    this.accessToken = localStorage.getItem(constants.AccessTokenKey);
    return this.accessToken;
  }

  setAccessToken(value) {
    this.accessToken = localStorage.setItem(constants.AccessTokenKey, value);
  }

  clearAccessToken() {
    this.accessToken = localStorage.removeItem(constants.AccessTokenKey);
  }
}
export default new Authorization();
